* { 
  padding: 0;
  margin: 0;
}

html, #root, .App, body, .map{
  height: 100%;
  width: 100%;
  background-color: #1F2020;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

p {
  font-size: 1.5em;
}

h1 {
  font-size: 3.5em;
}

input {
  color: #E3EBFF;
}

nav {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
}

nav .brand-logo { 
 color: #E3EBFF;
}

.container {
  padding-top: 80px;
}

#email, #password{
  color: #E3EBFF;
  outline: none;
}

.search-box  input, .react-geocoder-item {
  background-color: rgba(0, 0, 0, 0.6);
  color: #E3EBFF;
  font-size: 2em;
  padding: 5px;
}

.search-results {
  background-color: rgba(0, 0, 0, 0.6);
  color: #E3EBFF;
  font-size: 2em;
  padding: 0;
  margin: 0;
  text-align: center;
}

.search-result {
  color: #E3EBFF;
  padding: 0;
  margin: 0;
}

.react-geocoder-item:hover, .search-result:hover {
  background-color: #E3EBFF;
  color: #1F2020;
  cursor: pointer;
}

.fa-map-marker-alt {
  color: #3B0075;
  text-shadow: 5px 5px 2px #000000;
}

.fa-map-marker-alt:hover {
  cursor: pointer;
}

.container {
  height: 100%;
}

.white-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: #3B0075;
}

.white-box {
  background-color: #373635;
  padding: 75px 75px;
  text-align: center;
  color: #E3EBFF;
}

.white-box * {
  margin: 12px 0;
}

.white-box span{
  margin: 0;
}

.question {
  font-size: 2em;
}

.current-city {
  font-weight: bold;
  color: #3B0075;
  text-shadow: 2px 2px 4px #000000;
}

.MoonLoader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapboxgl-popup-content {
  text-align: center;
  padding: 20px;
}

.mapboxgl-popup-close-button {
  padding: 10px;
}

.side-nav a {
  color: #1F2020;
}

.side-nav .right {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

#content {
  height: 100%;
}

.input-field label{
  padding: 5px 2px;
}

.collection .collection-item {
  background-color: #1F2020;
}

.collection span {
  display: block;
}

.danger {
  background-color: #690404;
}

.warning {
  background-color: #EFBA4B;
  color: #1F2020;
}

#landing-title {
  color: #E3EBFF;
}

.subtitle {
  color: #E3EBFF;
  font-size: 2em;
  padding: 10px 0;
}

.landing-jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  width: 60%;
  margin: 0 auto;
}

.landing-container {
  background: url('./assets/images/rainbow_world_map.png') no-repeat center center fixed;
  z-index: 1;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ranking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 35px;
  margin: 0 auto;
  width: 100%;
}

.no-ranking {
  font-size: 2em;
  color: red;
}

.icon {
  height: 75px;
  width: 75px;
  cursor: pointer;
}

.map-icon {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

@media (max-width: 1200 px) {
  /* Desktops */
}

@media (max-width: 990px) {
  /* Tablets */
  h1 {
    font-size: 2.8em;
  }

  #subtitle {
    font-size: 1.8em;
    padding: 3% 0;
  }

  .landing-jumbotron {
    width: 80%;
  }

  .landing-container {
    background: none;
  }

  .white-box {
    padding: 60px 15px;
  }

  .search-box  input, .search-results, .react-geocoder-item {
    background-color: rgba(0, 0, 0, 0.6);
    color: #E3EBFF;
    padding: 5px;
  }

  .icon {
    height: 55px;
    width: 55px;
  }

}

@media (max-width: 475px) {
  /* Mobile Devices */
  h1 {
    font-size: 2.5em;
  }

  .question {
    font-size: 1.5em;
  }

  .white-box {
    padding: 60px 15px;
  }

  #subtitle {
    font-size: 1.2em;
    padding: 5% 0;
  }

  .landing-jumbotron {
    width: 90%;
    background-color: #1F2020;
  }

  .landing-container {
    background: #1F2020;
  }

  .search-box  input, .search-results, .react-geocoder-item {
    font-size: 1.5em;
    background-color: rgba(0, 0, 0, 0.6);
    color: #E3EBFF;
    padding: 5px;
  }

  .icon {
    height: 40px;
    width: 40px;
  }

  p {
    font-size: 1em;
  }
}

@media (max-width: 375px) {
  /* Tiny Mobile Devices */
  h1 {
    font-size: 1.8em;
  }

  .question {
    font-size: 1em;
  }

  .white-box {
    padding: 60px 15px;
  }

  .search-box  input, .search-results, .react-geocoder-item {
    font-size: 1.1em;
    background-color: rgba(0, 0, 0, 0.6);
    color: #E3EBFF;
    padding: 5px;
  }

  .icon {
    height: 35px;
    width: 35px;
  }

  p {
    font-size: 0.8em;
  }
}